import React, { useState, useEffect, useRef } from "react";
import "../style/HomePage.css";
import baseUrl from "../utilities/globalVariables.js";
import Recaptcha from "react-google-recaptcha";
import siteKey from "../utilities/recaptchaKeys.js";

const maxFiles = 5;
const maxDimension = 10000000;

function InvioModello() {
    const reRef = useRef();
    const [testo, setTesto] = useState("");
    const [subject, setSubject] = useState("Condotte Illecite");
    const [aziendaId, setAziendaId] = useState(1);
    const [listaAziende, setListaAziende] = useState([]);
    const [foto, setFoto] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [error, setError] = useState("");
    const [avviso, setAvviso] = useState("");
    const [loading, setLoading] = useState(false);
    const listaOggetto = [
        "Condotte Illecite",
        "Violazioni di norme",
        "Azioni suscettibili di arrecare pregiudizi patrimoniale o di immagine aziendale",
        "Violazioni del presente Codice Etico",
        "Violazioni del Modello 231",
        "Violazioni di procedure e disposizioni aziendali",
        "Altro"
    ];

    useEffect(() => {
        fetch(baseUrl + "api/v1/aziende231")
        .then((response) => response.json())
        .then((result) => setListaAziende(result.message))
        .catch((error) => console.log("error", error));
    }, []);

    function handleInputImages(e) {
        const files = e.target.files;
        if (files.length > maxFiles) {
            setFoto([]);
            setError(`Non puoi caricare più di ${maxFiles} immagini`);
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size > maxDimension) {
                setFoto([]);
                setError(
                `I files non possono superare singolarmente ${maxDimension / 1000000}Mb`
                );
                return;
            }
        }

        setFoto(files);
        setError("");
    }

    function handleInserimentoRichiesta231() {
        setAvviso("");
        setLoading(true);

        reRef.current.executeAsync().then((token) => {
        reRef.current.reset();
        if (token === "undefined" || token === undefined) {
            setLoading(false);
            return;
        }
        });

        if (!testo) {
            setAvviso("");
            setErrorMessage("Tutti i campi sono obbligatori");
            setLoading(false);
            return;
        }

        if (foto.length > maxFiles) {
            setError("Numero massimo di foto superato");
            setLoading(false);
            return;
        }
    
        setError("");

        const formData = new FormData();
        const nomeAz = listaAziende.find((oggetto) => oggetto.id === aziendaId)?.name;
        formData.append("text", testo);
        formData.append("subject", subject);
        formData.append("aziendaId", aziendaId);
        formData.append("aziendaNome", nomeAz);

        for (let i = 0; i < foto.length; i++) {
            formData.append("images", foto[i]);
        }

        const requestOptions = {
            method: "POST",
            body: formData,
            redirect: "follow"
        };

        fetch(baseUrl + "api/v1/richieste231", requestOptions)
        .then((response) => response.text())
        .then((res) => {
            console.log(res);
            if (res.error) {
            setErrorMessage(res.error);
            setAvviso("");
            } else {
            setErrorMessage("");
            setTesto("");
            setAvviso("Segnalazione inviata!");
            setFoto([]);
            }
            setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }


    return (
        <div>
        <Recaptcha sitekey={siteKey} size="invisible" ref={reRef} />
        <h2 style={{ fontSize: "x-large", marginBottom: "2vh" }}>MODELLO 231</h2>
        <label htmlFor="listaAziendeValue">
            Seleziona la società del gruppo AVR per la quale vuoi fare la segnalazione
        </label>
        <br />
        <select
            name="listaAziende"
            id="listaAziendeValue"
            value={aziendaId}
            onChange={(e) => setAziendaId(+e.target.value)}
            className="selectModello231"
        >
            {listaAziende.sort((a, b) => a.id - b.id).map((azienda) => (
            <option key={azienda.id} value={+azienda.id}>
                {azienda.name}
            </option>
            ))}
        </select>

        <label htmlFor="listaOggetti">Seleziona l'oggetto della segnalazione</label>
        <br />
        <select
            name="listaOggetti"
            id="listaOggetti"
            onChange={(e) => setSubject(e.target.value)}
            className="selectModello231"
        >
            {listaOggetto.map((oggetto, i) => (
            <option key={i} value={oggetto}>
                {oggetto}
            </option>
            ))}
        </select>

        <div className="insertImages">
            <label className="mostraUtentiButton" htmlFor="imagesInput">
            Allega Immagini
            </label>
            <input
            type="file"
            id="imagesInput"
            accept="image/*"
            name="images"
            aria-label="File browser"
            style={{ display: "none" }}
            multiple
            onChange={handleInputImages}
            />
            {foto.length > 0 && (
            <div style={{ display: "inline" }}>Files selezionati: {foto.length}</div>
            )}
            {error !== "" && <div style={{ display: "inline", color: "red" }}>{error}</div>}
        </div>

        <label htmlFor="textareaAnonyme">Inserisci un testo per la segnalazione</label>
        <br />
        <textarea
            id="textareaAnonyme"
            className="textAreaAnonyme"
            value={testo}
            maxLength="350"
            onChange={(e) => setTesto(e.target.value)}
        ></textarea>
        <br />

        {loading ? (
            <div className="lds-dual-ring" style={{ margin: "0" }}></div>
        ) : (
            <button
            className="blueButtons"
            type="submit"
            onClick={handleInserimentoRichiesta231}
            style={{ padding: "0.5% 2%" }}
            >
            INVIA
            </button>
        )}
        <p style={{ color: "red", marginBottom: "1%" }}>{errorMessage}</p>
        <p style={{ color: "green", marginBottom: "1%" }}>{avviso}</p>
        </div>
    );
}

export default InvioModello;
