import React from 'react'
import baseUrl from '../utilities/globalVariables.js'
import azureEnvironment from '../utilities/azureActiveDirectoryAppClient.js'
import {LogLevel,
    PublicClientApplication
} from "@azure/msal-browser";

export const MSAL_CONFIG = {
    // change development to production before pushing to git
        auth: {
            clientId: azureEnvironment.appId,
            authority: "https://login.microsoftonline.com/" + azureEnvironment.tenant,
            redirectUri: azureEnvironment.redirect,
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
        },
        system: {
            loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                return;
                }
                switch (level) {
                case LogLevel.Error:
                    //console.error(message);
                    return;
                case LogLevel.Info:
                    //console.info(message);
                    return;
                case LogLevel.Verbose:
                    console.debug(message);
                    return;
                case LogLevel.Warning:
                    //console.warn(message);
                    return;
                }
            },
            },
        },
};
let myMSALObj = new PublicClientApplication(MSAL_CONFIG);

function SsoButton({nextFunction}) {
    //nextFunction if success call the next function wich needs(samlEmail)
    //samlEmail passes to parent function the succesfull result 
    const goToDashboard = () => {
        myMSALObj
        .loginPopup({
            scopes: [],
            prompt: "select_account",
            })
            .then((resp) => {
            //console.log('MSAL', resp);
            if(resp.idToken && resp.account && resp.account.name){
                let SAMLemail = resp.account.username.toLowerCase()
                nextFunction(SAMLemail)
                }
            })
            .catch((err) => {
            console.error(err);
        });
    };

    return (
        <div>
            <button  className="buttonSignin " style={{marginTop:'1%'}} id="accediSAMLButton" onClick={() => goToDashboard()} >ACCESSO MODERATORI</button>
        </div>
    )
}

export default SsoButton
